import React from "react";
import "./blog.css";
const Blog1 = () => {
    return (
        <div className="blog-details-container">
            <h1>Set out on Your Learning Journey with NanoQuestTech!</h1>

            <section>
                <p>
                    The world of innovation is ever-evolving, and keeping up
                    with the most recent progressions can feel overpowering.
                    Whether you are a prepared proficient looking to refine your
                    abilities or an inquisitive beginner taking your first
                    steps, the need for open and engaging learning resources has
                    never been more prominent.
                </p>
                <p>
                    Presenting NanoQuestTech, your online destination for
                    high-quality, bite-sized educational courses designed to
                    engage you on your learning journey!
                </p>
            </section>
            <section>
                <h2>Why NanoQuestTech?</h2>
                <ul>
                    <li>
                        <strong>Micro-learning approach:</strong> Our courses
                        are broken down into brief, focused modules that fit
                        seamlessly into your busy schedule. Learn at your own
                        pace, anytime and anywhere.
                    </li>
                    <li>
                        <strong>Diverse course selection:</strong> Explore a
                        wide range of topics, from coding and data science to
                        design thinking and management skills. We have something
                        for everyone!
                    </li>
                    <li>
                        <strong>Expert instructors:</strong> Learn from industry
                        experts and passionate educators who are dedicated to
                        sharing their knowledge and expertise.
                    </li>
                    <li>
                        <strong>Interactive learning experience:</strong> Our
                        courses are packed with engaging activities, quizzes,
                        and real-world examples to keep you motivated and ensure
                        optimal knowledge retention.
                    </li>
                    <li>
                        <strong>Affordable and accessible:</strong> We believe
                        that everyone deserves access to quality education. Our
                        courses are competitively priced and accessible to
                        learners worldwide.
                    </li>
                </ul>
            </section>
            <section>
                <h2>Begin Your Learning Experience Today!</h2>
                <p>With NanoQuestTech, you'll:</p>
                <ul>
                    <li>
                        <strong>Acquire new skills:</strong> Equip yourself with
                        the knowledge and abilities required to thrive in
                        today's competitive job market.
                    </li>
                    <li>
                        <strong>Advance your career:</strong> Gain the skills
                        and knowledge needed to take your career to the next
                        level.
                    </li>
                </ul>
            </section>

            <footer>
                <p>&copy; 2024 NanoQuestTech. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default Blog1;

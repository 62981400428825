import { motion } from "framer-motion";

const testimonials = [
    "If you do the job badly enough, sometimes you don't get asked to do it again.",
    "I'm killing time while I wait for life to shower me with meaning and happiness.",
    "The only skills I have the patience to learn are those that have no real application in life.",
];

const Testimonials = () => {
    return (
        <div className="mt-20">
            <motion.h1
                initial={{ x: -50, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                viewport={{ once: true }}
                transition={{
                    delay: 0.5,
                    duration: 0.5,
                    type: "spring",
                    stiffness: 100,
                    damping: 10,
                }}
                className="text-center text-3xl font-semibold capitalize text-gray-500 mb-4"
            >
                Testimonials
            </motion.h1>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 p-4">
                {testimonials.map((testimonial, index) => (
                    <motion.figure
                        initial={{ y: 50, opacity: 0 }}
                        whileInView={{ y: 0, opacity: 1 }}
                        transition={{
                            delay: 0.2,
                            duration: 0.5,
                            type: "spring",
                            stiffness: 100,
                            damping: 10,
                        }}
                        className="relative w-full bg-white rounded-lg shadow-lg border-t-4 border-[#253e86] text-center md:max-w-sm"
                    >
                        <figcaption className="relative pt-[13%] pb-[12%] px-[10%]">
                            <div className="absolute top-[-36px] left-1/2 transform -translate-x-1/2 w-16 h-16 bg-white rounded-full shadow-lg flex items-center justify-center text-[#081F62] text-2xl">
                                <i className="fa fa-quote-right"></i>
                            </div>

                            <blockquote className="italic text-gray-600 text-base">
                                <p className="text-black capitalize">
                                    {testimonial}
                                </p>
                            </blockquote>
                        </figcaption>
                    </motion.figure>
                ))}
            </div>
        </div>
    );
};

export default Testimonials;

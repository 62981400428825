import React from "react";
import "./index.css";

const Careers = () => {
    return (
        <>
            <div className="career-cards-container grid grid-cols-1">
                <h3>Join Skill Revolution Platform</h3>
                <div className="careers-card">
                    <h4 className="apply-card-heading">HR Associate</h4>
                    <a href="https://airtable.com/app8mmy650SSrbZWy/pagiwmghHbjSG2Baq/form">
                        <button className="apply-button">Apply</button>
                    </a>
                </div>
                <div className="careers-card">
                    <h4 className="apply-card-heading">Graphic Designer</h4>
                    <a href="https://airtable.com/app8mmy650SSrbZWy/pagiwmghHbjSG2Baq/form">
                        <button className="apply-button">Apply</button>
                    </a>
                </div>
                <div className="careers-card">
                    <h4 className="apply-card-heading">Full Stack Developer</h4>
                    <a href="https://airtable.com/app8mmy650SSrbZWy/pagiwmghHbjSG2Baq/form">
                        <button className="apply-button">Apply</button>
                    </a>
                </div>
                <div className="careers-card">
                    <h4 className="apply-card-heading">Content</h4>
                    <a href="https://airtable.com/app8mmy650SSrbZWy/pagiwmghHbjSG2Baq/form">
                        <button className="apply-button">Apply</button>
                    </a>
                </div>
                <div className="careers-card">
                    <h4 className="apply-card-heading">Digital Martketing</h4>
                    <a href="https://airtable.com/app8mmy650SSrbZWy/pagiwmghHbjSG2Baq/form">
                        <button className="apply-button">Apply</button>
                    </a>
                </div>
                <div className="careers-card">
                    <h4 className="apply-card-heading">Sales</h4>
                    <a href="https://airtable.com/app8mmy650SSrbZWy/pagiwmghHbjSG2Baq/form">
                        <button className="apply-button">Apply</button>
                    </a>
                </div>
            </div>
        </>
    );
};

export default Careers;

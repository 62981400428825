import React from "react";
import aboutUs from "../../assets/aboutus.jpg";

const AboutUs = () => {
    return (
        <section className="py-16 mt-10">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col md:flex-row items-center justify-between space-y-10 md:space-y-0 md:space-x-10">
                    <div className="md:w-1/2 space-y-6">
                        <h2 className="text-3xl font-bold text-gray-900 leading-snug mb-4">
                            Welcome to Nanoquest
                        </h2>
                        <p className="text-gray-700 text-lg leading-relaxed">
                            At Nanoquest, we are dedicated to fostering skill
                            enhancement for online learners in a way that's
                            engaging, inspiring, and effective. Our interactive
                            platform is crafted to help individuals unlock their
                            potential, tailored for a wide range of learning
                            needs—whether you're advancing in your career,
                            exploring new hobbies, or expanding your knowledge.
                        </p>
                        <p className="text-gray-700 text-lg leading-relaxed">
                            Our culture revolves around innovation,
                            collaboration, and a passion for learning. We aim to
                            create an inclusive environment where everyone can
                            thrive. Join us on this journey and discover the
                            limitless possibilities that Nanoquest offers.
                        </p>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                                Our Vision
                            </h3>
                            <p className="text-gray-700 text-lg">
                                To create a world where everyone can develop the
                                skills necessary to succeed in the digital age.
                            </p>
                        </div>
                        <div>
                            <h3 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
                                Our Mission
                            </h3>
                            <p className="text-gray-700 text-lg">
                                We are committed to providing accessible,
                                high-quality education that empowers individuals
                                to achieve their goals. By bridging the skills
                                gap, we support learners throughout their career
                                journeys with diverse resources and courses.
                            </p>
                        </div>
                    </div>
                    <div className="md:w-1/2 flex justify-center">
                        <img
                            src={aboutUs}
                            alt="About Nanoquest"
                            className="rounded-lg shadow-lg transform hover:scale-105 transition duration-500 ease-in-out"
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;

import React from "react";
import "./index.css";

const TermsAndConditions = () => {
    return (
        <>
            <div className="terms-and-conditions-container">
                <h1>Terms and Conditions</h1>

                <div className="section">
                    <h3>1. Acceptance of Terms</h3>
                    <p>
                        Welcome to NANOQUEST LLP! These terms and conditions
                        outline the rules and regulations for the use of
                        NANOQUEST's Website, located at{" "}
                        <a href="https://nanoquesttech.in">
                            https://nanoquesttech.in
                        </a>
                        . By accessing this website, we assume you accept these
                        terms and conditions. Do not continue to use NANOQUEST
                        LLP if you do not agree to take all of the terms and
                        conditions stated on this page.
                    </p>
                </div>

                <div className="section">
                    <h3>2. Cookies</h3>
                    <p>
                        The website uses cookies to help personalize your online
                        experience. By accessing NANOQUEST LLP, you agree to use
                        the required cookies. A cookie is a text file that is
                        placed on your hard disk by a web page server. Cookies
                        cannot be used to run programs or deliver viruses to
                        your computer. Cookies are uniquely assigned to you and
                        can only be read by a web server in the domain that
                        issued the cookie to you. We may use cookies to collect,
                        store, and track information for statistical or
                        marketing purposes to operate our website. You have the
                        ability to accept or decline optional Cookies. There are
                        some required Cookies that are necessary for the
                        operation of our website. These cookies do not require
                        your consent as they always work. Please keep in mind
                        that by accepting required Cookies, you also accept
                        third-party Cookies, which might be used via third-party
                        provided services if you use such services on our
                        website, for example, a video display window provided by
                        third parties and integrated into our website.
                    </p>
                </div>

                <div className="section">
                    <h3>3. License</h3>
                    <p>
                        Unless otherwise stated, NANOQUEST and/or its licensors
                        own the intellectual property rights for all material on
                        NANOQUEST LLP. All intellectual property rights are
                        reserved. You may access this from NANOQUEST LLP for
                        your own personal use subjected to restrictions set in
                        these terms and conditions. You must not:
                        <ul>
                            <li>
                                Copy or republish material from NANOQUEST LLP
                            </li>
                            <li>
                                Sell, rent, or sub-license material from
                                NANOQUEST LLP
                            </li>
                            <li>
                                Reproduce, duplicate, or copy material from
                                NANOQUEST LLP
                            </li>
                            <li>Redistribute content from NANOQUEST LLP</li>
                        </ul>
                        This Agreement shall begin on the date hereof. Parts of
                        this website offer users an opportunity to post and
                        exchange opinions and information in certain areas of
                        the website. NANOQUEST does not filter, edit, publish,
                        or review Comments before their presence on the website.
                        Comments do not reflect the views and opinions of
                        NANOQUEST, its agents, and/or affiliates. Comments
                        reflect the views and opinions of the person who posts
                        their views and opinions. To the extent permitted by
                        applicable laws, NANOQUEST shall not be liable for the
                        Comments or any liability, damages, or expenses caused
                        and/or suffered as a result of any use of and/or posting
                        of and/or appearance of the Comments on this website.
                        NANOQUEST reserves the right to monitor all Comments and
                        remove any Comments that can be considered
                        inappropriate, offensive, or causes a breach of these
                        Terms and Conditions. You warrant and represent that:
                        <ul>
                            <li>
                                You are entitled to post the Comments on our
                                website and have all necessary licenses and
                                consents to do so;
                            </li>
                            <li>
                                The Comments do not invade any intellectual
                                property right, including without limitation
                                copyright, patent, or trademark of any third
                                party;
                            </li>
                            <li>
                                The Comments do not contain any defamatory,
                                libelous, offensive, indecent, or otherwise
                                unlawful material, which is an invasion of
                                privacy.
                            </li>
                            <li>
                                The Comments will not be used to solicit or
                                promote business or custom or present commercial
                                activities or unlawful activity.
                            </li>
                        </ul>
                        You hereby grant NANOQUEST a non-exclusive license to
                        use, reproduce, edit, and authorize others to use,
                        reproduce, and edit any of your Comments in any and all
                        forms, formats, or media.
                    </p>
                </div>

                <div className="section">
                    <h3>4.Hyperlinking to our Content</h3>
                    <p>
                        The following organizations may link to our Website
                        without prior written approval:
                        <ul>
                            <li>Government agencies;</li>
                            <li>Search engines;</li>
                            <li>News organizations;</li>
                            <li>
                                Online directory distributors may link to our
                                Website in the same manner as they hyperlink to
                                the Websites of other listed businesses; and
                            </li>
                            <li>
                                System-wide Accredited Businesses except
                                soliciting non-profit organizations, charity
                                shopping malls, and charity fundraising groups
                                which may not hyperlink to our Website.
                            </li>
                        </ul>
                        These organizations may link to our home page, to
                        publications, or to other Website information so long as
                        the link:
                        <ol>
                            <li>Is not in any way deceptive;</li>
                            <li>
                                Does not falsely imply sponsorship, endorsement,
                                or approval of the linking party and its
                                products and/or services; and
                            </li>
                            <li>
                                Fits within the context of the linking party's
                                site.
                            </li>
                        </ol>
                        We reserve the right to request that you remove all
                        links or any particular link to our Website. You approve
                        to immediately remove all links to our Website upon
                        request. We also reserve the right to amend these terms
                        and conditions and its linking policy at any time. By
                        continuously linking to our Website, you agree to be
                        bound to and follow these linking terms and conditions.
                    </p>
                </div>

                <div className="section">
                    <h3>5.Content Liability</h3>
                    <p>
                        We shall not be held responsible for any content that
                        appears on your Website. You agree to protect and defend
                        us against all claims that are raised on your Website.
                        No link(s) should appear on any Website that may be
                        interpreted as libelous, obscene, or criminal, or which
                        infringes, otherwise violates, or advocates the
                        infringement or other violation of, any third party
                        rights.
                    </p>
                </div>

                <div className="section">
                    <h3>6.Reservation of Rights</h3>
                    <p>
                        We reserve the right to request that you remove all
                        links or any particular link to our Website. You approve
                        to immediately remove all links to our Website upon
                        request. We also reserve the right to amend these terms
                        and conditions and its linking policy at any time. By
                        continuously linking to our Website, you agree to be
                        bound to and follow these linking terms and conditions.
                    </p>
                </div>

                <div className="section">
                    <h3>7.Removal of links from our website</h3>
                    <p>
                        If you find any link on our Website that is offensive
                        for any reason, you are free to contact and inform us at
                        any moment. We will consider requests to remove links,
                        but we are not obligated to or so or to respond to you
                        directly. We do not ensure that the information on this
                        website is correct. We do not warrant its completeness
                        or accuracy, nor do we promise to ensure that the
                        website remains available or that the material on the
                        website is kept up to date.
                    </p>
                </div>

                <div className="section">
                    <h3>8.Disclaimer</h3>
                    <p>
                        To the maximum extent permitted by applicable law, we
                        exclude all representations, warranties, and conditions
                        relating to our website and the use of this website.
                        Nothing in this disclaimer will:
                        <ul>
                            <li>
                                Limit or exclude our or your liability for death
                                or personal injury;
                            </li>
                            <li>
                                Limit or exclude our or your liability for fraud
                                or fraudulent misrepresentation;
                            </li>
                            <li>
                                Limit any of our or your liabilities in any way
                                that is not permitted under applicable law; or
                            </li>
                            <li>
                                Exclude any of our or your liabilities that may
                                not be excluded under applicable law.
                            </li>
                        </ul>
                        The limitations and prohibitions of liability set in
                        this Section and elsewhere in this disclaimer: (a) are
                        subject to the preceding paragraph; and (b) govern all
                        liabilities arising under the disclaimer, including
                        liabilities arising in contract, in tort, and for breach
                        of statutory duty.
                    </p>
                </div>
            </div>
        </>
    );
};

export default TermsAndConditions;

const SkillCourseCard = ({ item, addToCart, toggleReadMore }) => {
    const { _id, title, description, price, isDescriptionExpanded } = item;
    const maxLength = 150;

    return (
        <div className="shadow-lg rounded-lg p-3 bg-white hover:shadow-xl transition-all duration-300 ease-in-out">
            <h3 className="text-xl font-bold text-gray-800 mb-2">{title}</h3>
            <p className="text-sm text-gray-700 mb-3 capitalize">
                {isDescriptionExpanded
                    ? description
                    : `${description.slice(0, maxLength)}`}

                {description.length > maxLength && !isDescriptionExpanded
                    ? "..."
                    : ""}
                {description.length > maxLength && (
                    <span
                        onClick={() => toggleReadMore(_id)}
                        className="text-blue-500 cursor-pointer ml-1"
                    >
                        {isDescriptionExpanded ? "Show less" : "Read more"}
                    </span>
                )}
            </p>
            <div className="flex justify-between items-center mt-4">
                <p className="text-blue-600 font-semibold">
                    {price !== 0 ? `Rs. ${price}` : "Free"}
                </p>
                <button
                    onClick={() => addToCart(_id, title, price)}
                    className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                    Enroll Now
                </button>
            </div>
        </div>
    );
};

export default SkillCourseCard;
